import { TextField } from '@mui/material';
import { useField } from 'formik';
const CustomTextField = ({ label, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <TextField {...field} {...props} label={label} variant="outlined" />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
export default CustomTextField;