import React from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { useField } from 'formik';
const CustomSelect = ({ label, list, onChange, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        {...field}
        {...props}
      >
        {list?.map((el: { id: number, name: string }) => {
          return <MenuItem key={el.id} value={el.id}>{el?.name}</MenuItem>
        })}
      </Select>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
export default CustomSelect;