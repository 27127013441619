import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useDeleteSearchedCarsMutation, useGetSearchedCarsByIdQuery } from '../../store/services/searchedCars';


function SearchedCarsId() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: carData } = useGetSearchedCarsByIdQuery({
    id,
  });
  const [deleteCar] = useDeleteSearchedCarsMutation();
  const printData = carData?.result[0];

  return <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', width: '500px' }}>
    <Typography gutterBottom variant="h5" component="div">{t('technicalSpecification')}</Typography>
    <Table>
      <TableRow>
        <TableCell variant="head">{t('mark')} / {t('model')}</TableCell>
        <TableCell>{printData?.modelName} / {printData?.markName}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('carColor')}</TableCell>
        <TableCell>{printData?.carColor}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('driveType')}</TableCell>
        <TableCell>{printData?.driveType}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('engineVolume')}</TableCell>
        <TableCell>{printData?.engineVolume}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('hp')}</TableCell>
        <TableCell>{printData?.hp}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('interier')}</TableCell>
        <TableCell>{printData?.interier}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('interierColor')}</TableCell>
        <TableCell>{printData?.interierColor}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('mileage')}</TableCell>
        <TableCell>{printData?.mileage}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('options')}</TableCell>
        <TableCell>{printData?.options}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('phoneNumber')}</TableCell>
        <TableCell>{printData?.phoneNumber}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('price')}</TableCell>
        <TableCell>{printData?.price}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('primaryDamage')}</TableCell>
        <TableCell>{printData?.primaryDamage}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('transmission')}</TableCell>
        <TableCell>{printData?.transmission}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">{t('date')}</TableCell>
        <TableCell>{printData?.year}</TableCell>
      </TableRow>
    </Table>
  </Box>
}
export default SearchedCarsId;