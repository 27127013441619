import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";

function Utils() {
  const navigate = useNavigate();
  const {t}=useTranslation();
  const generateDate = () => {
    let i = 2000;
    let numberArray = [];
    while (i < 2051) {
      numberArray.push(i)
      i++
    }
    return numberArray;
  }
 
  const SignupSchema = Yup.object().shape({
  
    marksId: Yup.string()
      .required(t('required')),
    year: Yup.number()
    .required(t('required')),
    price: Yup.number()
    .required(t('required')),
      mileage: Yup.number()
      .required(t('required')),
      mileageType: Yup.string()
      .required(t('required')),
      location: Yup.string(),
      vin: Yup.string()
      .required(t('required')),
      descriptionArm: Yup.string()
      .required(t('required')),
      descriptionRu: Yup.string()
      .required(t('required')),
      descriptionEn: Yup.string()
      .required(t('required')),
      colorArm: Yup.string()
      .required(t('required')),
      colorRu: Yup.string()
      .required(t('required')),
      colorEn: Yup.string()
      .required(t('required')),
      // driveType: Yup.string()
      // .required(t('required')),
      transmission: Yup.string()
      .required(t('required')),
      body: Yup.string()
      .required(t('required')),
      wheel: Yup.string()
      .required(t('required')),
  });

  return {

    SignupSchema,
    generateDate,
    navigate
  }
}
export default Utils;