import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField } from '@mui/material';
import { useDeleteCarMutation, useEditCarMutation, useGetCarsByMarkQuery } from './store/services/cars';
import { useGetMarkQuery, useGetModelQuery } from './store/services/markmodel';
import DeleteModal from './Components/DeleteModal';
import { ICars, IMark, IModel } from './types';
// querinery  markId, startYear,endYear,priceFrom, priceTo,isFavorite true kam false
export default function App() {
  const [from, setFrom] = useState<number>();
  const [to, setTo] = useState<number>();
  const [mark, setMark] = useState<number>();
  const [model, setModel] = useState<number>();
  const [priceFrom, setPriceFrom] = useState<number>();
  const [priceTo, setPriceTo] = useState<number>();
  const [location, setLocation] = useState<string>();
  const [deletedItem, setDeletedItem] = useState<number | null>(null);
  const [page, setPage] = useState(1);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { data } = useGetModelQuery({ limit: 1000000 });
  const { data: dataMark } = useGetMarkQuery({ modelId: model, limit: 1000000 });
  const { data: carData } = useGetCarsByMarkQuery({
    markId: mark,
    startYear: from,
    endYear: to,
    priceFrom,
    priceTo,
    location,
    offset: (page - 1) * 10,
    limit: 10
  });
  const [editCar] = useEditCarMutation();
  const [deleteCar] = useDeleteCarMutation();

  const handleChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);

  const generateDate = () => {
    let i = 2000;
    let numberArray = [];
    while (i < 2051) {
      numberArray.push(i)
      i++
    }
    return numberArray;
  }

  const handleOpen = useCallback((id: number) => {
    setDeletedItem(id)
  }, [])

  const handleClose = useCallback(() => {
    setDeletedItem(null)
  }, [])

  const deleteFunction = useCallback(() => {
    deleteCar({ id: deletedItem })
    handleClose()
  }, [deletedItem])

  const handleSelectFrom = useCallback((el: number) => {
    setFrom(el)
  }, [])

  const handleSelectTo = useCallback((el: number) => {
    setTo(el)
  }, [])

  const handleSelectLocation = useCallback((el: string) => {
    setLocation(el)
  }, [])

  const handleSelectMark = useCallback((el: number) => {
    setMark(el)
  }, [])

  const handleSelectModel = useCallback((el: number) => {
    setModel(el)
  }, [])

  const handlePriceFrom = useCallback((el: number) => {
    setPriceFrom(el)
  }, [])

  const handlePriceTo = useCallback((el: number) => {
    setPriceTo(el)
  }, [])

  const handleChangeCar = useCallback((el: ICars) => {
    const { modelId, modelName, imagePath, imageId, mark_name, count, ...rest } = el;
    editCar({ ...rest, isFavorite: !rest.isFavorite, driveType:'front_wheel_drive', soldOut: !!rest.soldOut })
  }, [])

  const handleChangeCarSoldOut = useCallback((el: ICars) => {
    const { modelId, modelName, imagePath, imageId, mark_name, count, ...rest } = el;
    editCar({ ...rest, soldOut: !rest.soldOut, driveType:'front_wheel_drive', isFavorite: !!rest.isFavorite, })
  }, [])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1976d2',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
      <DeleteModal
        deleteFunction={deleteFunction}
        handleClose={handleClose}
        open={!!deletedItem}
      />
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'end', flexWrap: 'wrap' }}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('mark')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={model}
              onChange={(el: any) => handleSelectModel(el.target.value)}
              label={t('mark')}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {data?.result?.map((el: IModel) => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('model')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mark}
              onChange={(el: any) => handleSelectMark(el.target.value)}
              label={t('model')}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {dataMark?.result?.map((el: IMark) => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('fromDate')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={from}
              onChange={(el: any) => handleSelectFrom(el.target.value)}
              label={t('fromDate')}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {generateDate().map((el) => <MenuItem key={el} value={el}>{el}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('toDate')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={to}
              onChange={(el: any) => handleSelectTo(el.target.value)}
              label={t('toDate')}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {generateDate().map((el) => <MenuItem key={el} value={el}>{el}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('location')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={location}
              onChange={(el: any) => handleSelectLocation(el.target.value)}
              label={t('location')}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {[
                { name: 'In the auction', id: 'in_the_auction' },
                { name: 'on the way', id: 'on_the_way' },
                { name: 'in Armenia', id: 'in_armenia' }]
                .map((el) => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth>
            <TextField
              value={priceFrom}
              onChange={(el: any) => handlePriceFrom(el.target.value)}
              label={t('priceFrom')}
            />
          </FormControl>
        </Box>

        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth>
            <TextField
              value={priceTo}
              onChange={(el: any) => handlePriceTo(el.target.value)}
              label={t('priceTo')}
            />
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} onClick={() => navigate(`/add-car`)}>
        <AddIcon />
      </Box>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('image')}</StyledTableCell>
                <StyledTableCell>{t('model')}/{t('mark')}</StyledTableCell>
                <StyledTableCell align="right">{t('description')}</StyledTableCell>
                <StyledTableCell align="right">{t('price')}</StyledTableCell>
                <StyledTableCell align="right">{t('date')}</StyledTableCell>
                <StyledTableCell align="right">{t('isFavorite')}</StyledTableCell>
                <StyledTableCell align="right">{t('soldOut')}</StyledTableCell>
                <StyledTableCell align="right">{t('edit')}</StyledTableCell>
                <StyledTableCell align="right">{t('delete')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carData?.result && carData?.result?.map((row: ICars, index: number) => {
                return <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    <img src={row?.imagePath ? `${process.env.REACT_APP_API_KEY}upload/getImageByName/${row?.imagePath}` : '/picture.jpg'} className='list-image' />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.mark_name} / {row.modelName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{i18n.language === 'am'
                    ? row.descriptionArm
                    : (i18n.language === 'ru' ? row.descriptionRu : row.descriptionEn)}</StyledTableCell>
                  <StyledTableCell align="right">{row.price}</StyledTableCell>
                  <StyledTableCell align="right">{row.year}</StyledTableCell>
                  <StyledTableCell align="right"><Checkbox checked={!!row?.isFavorite} onChange={() => { handleChangeCar(row) }} /></StyledTableCell>
                  <StyledTableCell align="right"><Checkbox checked={!!row?.soldOut} onChange={() => { handleChangeCarSoldOut(row) }} /></StyledTableCell>
                  <StyledTableCell align="right"><EditIcon className='cusor-pointer' onClick={() => navigate(`/cars/${row.id}`)} /></StyledTableCell>
                  <StyledTableCell align="right"><DeleteIcon onClick={() => handleOpen(row.id)} className='cusor-pointer' /></StyledTableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
          {carData?.result[0]?.count > 10 && <Pagination count={Math.ceil(carData?.result[0]?.count / 10)} color="primary" page={page} onChange={handleChange} />}
        </TableContainer>
      </Box>
    </Box>

  );
}
