import { Box, Button, ImageListItem, List, ListItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useCallback, useState, useEffect } from 'react';
import { useAddDashImagesMutation, useDeleteDashImagesMutation, useGetDashImagesQuery } from '../../store/services/dashImages';
import DeleteModal from '../../Components/DeleteModal';
import { deleteImage } from '../../store/slices/images';
import { useAddImagesMutation } from '../../store/services/photo';
import { useAppDispatch } from '../../store';


function DashImages() {
  const { t } = useTranslation();
  const [deletedItem, setDeletedItem] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const { data } = useGetDashImagesQuery({ limit: 10, offset: (page - 1) * 10 });
  const [deleteImages] = useDeleteDashImagesMutation({});
  const [addDashImages] = useAddDashImagesMutation();
  const dispatch = useAppDispatch();

  const handleOpenDeleteModal = useCallback((id: number) => {
    setDeletedItem(id)
  }, [])
  const handleCloseDeleteModal = useCallback(() => {
    setDeletedItem(null)
  }, [])
  const deleteFunction = useCallback(() => {
    deleteImages({ id: deletedItem })
    handleCloseDeleteModal()
  }, [deletedItem])
  const handleChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);
  const handleDeleteUploadFiles = (ind: any) => {

    dispatch(deleteImage(ind))
  }
  const onChangeHandler = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const formdata = new FormData();
      formdata.append('image', file)
      addDashImages(formdata);
    }

  };

  return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography gutterBottom variant="h5" component="div">{t('dashImages')}</Typography>

    <DeleteModal
      deleteFunction={deleteFunction}
      handleClose={handleCloseDeleteModal}
      open={!!deletedItem}
    />

    <Stack sx={{ display: 'flex', justifyContent: 'center', mb: 3 }} direction="row" alignItems="center" spacing={2}>
      <Button variant="contained" component="label">
      {t('upload')}
        <input hidden accept="image/*" type="file" onChange={(e) => {
          onChangeHandler(e)
        }} />
      </Button>
    </Stack>
    <List
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        height: '100%',
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
      {data?.result?.map((item: any) => (
        <Box sx={{
          position: 'relative',
          width: 360,
        }}><ListItem key={item.id}>
            <ImageListItem key={item}>
              <img
                style={{ height: '300px' }}
                src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.path}`}
                srcSet={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.path}`}
                alt='img'
                loading="lazy"
              />
            </ImageListItem>

            <Box sx={{ position: 'absolute', top: '20px', right: '30px', zIndex: 1, background: 'white', borderRadius: '50%', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><DeleteIcon onClick={() => item.id ? handleOpenDeleteModal(item.id) : console.log('fg')
            } className='cusor-pointer' /></Box>
          </ListItem></Box>
      ))}
    </List>
    {data?.result[0] && data?.result[0]?.count > 10 && <Pagination count={Math.ceil(data?.result[0]?.count / 10)} color="primary" page={page} onChange={handleChange} />}
  </Box>
}
export default DashImages;