import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormControl, Typography, Box, Button, RadioGroup, FormControlLabel, Radio, Select, MenuItem } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../Components/CustomSelect';
import CustomTextField from '../../Components/CustomTextFIeld/idex';
import Utils from './utils';
import { useEditCarMutation, useGetCarsByIdQuery } from '../../store/services/cars';
import Callery from './Gallery';
import { useParams } from 'react-router-dom';
import { useGetMarkQuery, useGetModelQuery } from '../../store/services/markmodel';
import { useAppDispatch, useAppSelector } from '../../store';
import { setImages } from '../../store/slices/images';
import { IModel } from '../../types';

function Carpage() {
  const {

    SignupSchema,
    generateDate,
    navigate
  } = Utils();
  const { t, i18n } = useTranslation();
  const [editCar, { isLoading, error, isSuccess }] = useEditCarMutation();
  const { id } = useParams();
  const [model, setModel] = useState<number>();
  const { data: dataModel, isLoading: isLoadingModel } = useGetModelQuery({ limit: 1000000 });
  const { data: dataMark, isLoading: isLoadingMark } = useGetMarkQuery({ modelId: model, limit: 1000000 });
  const { data: dataCar, isLoading: isLoadingCar } = useGetCarsByIdQuery({ id });
  const images = useAppSelector((state) => state.images.images);
  const dispatch = useAppDispatch();
  const successToast = () => toast.success(t('edit-success'));
  interface Values {
    "location": any,
    "vin": any,
    "marksId": any, 
    "modelId": any,
    "year": any,
    "mileage": any,
    "mileageType": any,
    "colorArm": any,
    "colorRu": any,
    "colorEn": any,
    "engineVolume": any, 
    "driveType": any,
    "price": any,
    "descriptionArm": any, 
    "descriptionRu": any,
    "descriptionEn": any, 
    "transmission":any,
    "engineType":any
    "body":any,
    "wheel":any
  }
  const errorToast = (err: any) => toast.error(err ? err.error : 'someting went wrong');
  useEffect(() => {
    if (error) {
      errorToast(error);
    }
  }, [error])

  useEffect(() => {
    const selected_model = dataModel?.result.filter((el) => el.id === dataCar?.result[0].modelId)[0]?.id;
    setModel(selected_model);
  }, [dataCar, dataModel])
  console.log(model,'modelmodelmodel');
  
  const handleSelectMark = (el: number) => {
    setModel(el)
  }

  useEffect(() => {
    return function cleanup() {
      dispatch(setImages([]))
    };
  }, []);
  useEffect(() => {
   if (isSuccess) {
    successToast()
   }
  }, [isSuccess]);
  const fuelType = [
    { id: '1', name: 'Petrol' },
    { id: '3', name: 'Hybrid' },
    { id: '2', name: 'Diesel' },
    { id: '4', name: 'Full Electric' },
];
  return <>
    <Box>
      <Typography gutterBottom variant="h5" component="div">
        {t('edit')}
      </Typography>

      <Callery id={id} />
      {!isLoadingCar && <Box className='responsive-form' sx={{ gap: 3, width: '60%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Formik
            initialValues={{
              "location": dataCar?.result[0]?.location,
              "vin": dataCar?.result[0]?.vin,
              "marksId": dataCar?.result[0]?.marksId,
              "modelId": dataModel?.result.filter((el) => el.id === dataCar?.result[0].modelId)[0]?.id,
              "year": dataCar?.result[0]?.year,
              "mileage": dataCar?.result[0]?.mileage,
              "mileageType": dataCar?.result[0]?.mileageType,
              "colorArm": dataCar?.result[0]?.colorArm,
              "colorRu": dataCar?.result[0]?.colorRu,
              "colorEn": dataCar?.result[0]?.colorEn,
              "engineVolume": dataCar?.result[0]?.engineVolume,
              "driveType": dataCar?.result[0]?.driveType,
              "price": dataCar?.result[0]?.price,
              "descriptionArm": dataCar?.result[0]?.descriptionArm,
              "descriptionRu": dataCar?.result[0]?.descriptionRu,
              "descriptionEn": dataCar?.result[0]?.descriptionEn,
              "transmission":dataCar?.result[0]?.transmission,
              "engineType":dataCar?.result[0]?.engineType,
              "body":dataCar?.result[0]?.body,
              "wheel":dataCar?.result[0]?.wheel,
            }}
            validationSchema={SignupSchema}
            onSubmit={(
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              try {
                const { modelId, ...rest } = values
                editCar({ ...rest, isFavorite: !!dataCar?.result[0].isFavorite, id,driveType:"front_wheel_drive", images: images.map((el: any) => el.id) })
              } catch (error: any) {
                errorToast(error.message)
              }

            }}
          >
            {({ errors, touched, handleChange }: any) => (
              <Form >
                <Box sx={{ maxWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={model?model:dataModel?.result.filter((el) => el.id === dataCar?.result[0].modelId)[0]?.id}
                      onChange={(el: any) => handleSelectMark(el.target.value)}
                      label={t('motor')}
                      name="motor"
                    >
                      {fuelType?.map((el:any) => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>
                {!isLoadingModel && <Box sx={{ maxWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={model?model:dataModel?.result.filter((el) => el.id === dataCar?.result[0].modelId)[0]?.id}
                      onChange={(el: any) => handleSelectMark(el.target.value)}
                      label={t('mark')}
                    >
                      {dataModel?.result?.map((el: IModel) => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>}
                {!isLoadingMark && <Box sx={{ maxWidth: 200, mb: 2 }}>
                  <FormControl fullWidth><CustomSelect
                    name='marksId'
                    label={t('model')}
                    list={dataMark?.result.filter((el) => el.model_id === model )}
                  /></FormControl>
                </Box>}
                <Box sx={{ maxWidth: 200, mb: 2 }}>
                  <FormControl fullWidth><CustomSelect
                    name='year'
                    label={t('date')}
                    list={generateDate()?.map((el) => ({ name: el, id: el }))}
                  /></FormControl>
                </Box>
                <Box sx={{ maxWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='price' label={t('price')} variant="outlined" type='number' />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='descriptionArm' label={t('descriptionArm')} variant="outlined" />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='descriptionRu' label={t('descriptionRu')} variant="outlined" />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='descriptionEn' label={t('descriptionEn')} variant="outlined" />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='colorArm' label={t('colorArm')} variant="outlined" />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='colorRu' label={t('colorRu')} variant="outlined" />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='colorEn' label={t('colorEn')} variant="outlined" />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='engineVolume' label={t('engineVolume')} variant="outlined" type='number' />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2, display: 'flex', alignItems: 'center' }}>
                  <RadioGroup
                    name="mileageType"
                    onChange={handleChange}
                    defaultValue='mile'
                  >
                    <FormControlLabel value="mile" control={<Radio />} label="Mile" />
                    <FormControlLabel value="km" control={<Radio />} label="Km" />
                  </RadioGroup>
                  <Box sx={{ minWidth: 100, mb: 2 }}>
                    <FormControl fullWidth>
                      <CustomTextField name='mileage' label={t('mileage')} type='number' variant="outlined" />
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomSelect
                      name='wheel'
                      label={t('wheel')}
                      list={[
                        { name: t('right'), id: 'right' },
                        { name: t('left'), id: 'left' },
                      ]}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomSelect
                      name='transmission'
                      label={t('transmission')}
                      list={[
                        { name: t('mechanical'), id: 'mechanical' },
                        { name: t('automatic'), id: 'automatic' },
                      ]}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomSelect
                      name='engineType'
                      label={t('engineType')}
                      list={[
                        { name: t('gasoline'), id: 'gasoline' },
                        { name: t('hybrid'), id: 'hybrid' },
                        { name: t('diesel'), id: 'diesel' },
                        { name: t('electric'), id: 'electric' },
                      ]}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomSelect
                      name='body'
                      label={t('body')}
                      list={[
                        { name: t('sedan'), id: 'sedan' },
                        { name: t('suv'), id: 'suv' },
                        { name: t('bigSuv'), id: 'big_suv' },
                        { name: t('pickup'), id: 'pickup' },
                        { name: t('motorcycle'), id: 'motorcycle' },
                        { name: t('miniven'), id: 'miniven' },
                      ]}
                    />
                  </FormControl>
                </Box>
                {/* <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomSelect
                      name='driveType'
                      label={t('driveType')}
                      list={[
                        { name: 'front wheel drive', id: 'front wheel drive' },
                        { name: 'rear drive', id: 'rear drive' },
                        { name: 'all wheel drive', is: 'all wheel drive' }]}
                    />
                  </FormControl>
                </Box> */}
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomSelect
                      name='location'
                      label={t('location')}
                      list={[{ name: 'In the auction', id: 'in_the_auction' }, { name: 'on the way', id: 'on_the_way' }, { name: 'in Armenia', id: 'in_armenia' }]}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 200, mb: 2 }}>
                  <FormControl fullWidth>
                    <CustomTextField name='vin' label='vin' variant="outlined" />
                  </FormControl>
                </Box>
                <Button type='submit' variant="contained">{t('edit')}</Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>}
    </Box>
  </>
}
export default Carpage;