import { Dialog, IconButton, Toolbar, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
interface IProps {
  deleteFunction: () => void,
  handleClose: () => void,
  open: boolean,
}
function DeleteModal(props: IProps) {
  const { t } = useTranslation();
  return <Dialog
    fullWidth={true}
    maxWidth="sm"
    open={props.open}
    onClose={props.handleClose}
  >
    <Box sx={{ maxWidth: '100%', width: '80%', margin: 'auto', gap: 3, paddingTop: '30px', paddingBottom: '30px' }}>
      <Typography variant="h6" noWrap component="div">{t('deleteLabel')}</Typography>
      <Button onClick={props.handleClose}>{t('cancel')}</Button>
      <Button onClick={props.deleteFunction}>{t('delete')}</Button>
    </Box>
  </Dialog>
}
export default DeleteModal;