import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';

function Lightbox(params: any) {
  const [current, setCurrent] = useState<any>();

  useEffect(() => {
    if (current) {
      current.slickGoTo(params?.index);
    }
  }, [current])

  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Box sx={{ right: '10px', color: 'black', fontSize: '30px' }}><ArrowForwardIosIcon /></Box>,
    prevArrow: <Box sx={{ left: '10px', color: 'black', fontSize: '30px' }}><ArrowBackIosIcon /></Box>
  };

  return (
    <Slider {...settings} ref={(slider) => {
      setCurrent(slider)
    }}>
      {params.itemData.map((el: any, ind: number) => (<div key={ind}>
        <div className='slider-image'>
          <img
            src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${el.path}`}
            alt='img'
            loading="lazy"
            className='w-100'
          />
        </div></div>))}
    </Slider>
  )
}
export default Lightbox;