import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImages } from '../../types';
interface IInitialState{
  images:IImages[]
}
const imagesSlice = createSlice({
  name: 'classrooms',
  initialState:{images:[]} as IInitialState,
  reducers: {
    deleteImage(state, action: any) { 
      console.log(action.payload,'action.payloadaction.payload');     
      state.images.splice(action.payload, 1)
    },
    setImages(state, action: PayloadAction<IImages[]>) {
      state.images=[...action.payload] 
    },
    addImagesSlice(state, action: any) {
      state.images.push(action.payload.result)
    },
  },
})
export const { deleteImage, setImages, addImagesSlice } = imagesSlice.actions
export default imagesSlice.reducer