import { Box, Button, Dialog, FormControl, List, ListItem, ListItemText, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import Pagination from '@mui/material/Pagination';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useCallback, useState, useEffect } from 'react';
import CustomTextField from '../../Components/CustomTextFIeld/idex';
import { Formik, FormikHelpers, Form } from 'formik';
import { useAddModelMutation, useGetModelQuery, useDeleteModelMutation } from '../../store/services/markmodel';
import DeleteModal from '../../Components/DeleteModal';


function Mark() {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [deletedItem, setDeletedItem] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const [addModel, { error }] = useAddModelMutation();
  const { data, } = useGetModelQuery({ limit: 10, offset: (page - 1) * 10 });
  const [deleteModel] = useDeleteModelMutation({});

  const errorToast = (err: any) => toast.error(err ? err.error : 'someting went wrong');

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleOpenDeleteModal = useCallback((id: number) => {
    setDeletedItem(id)
  }, [])

  const handleCloseDeleteModal = useCallback(() => {
    setDeletedItem(null)
  }, [])

  const deleteFunction = useCallback(() => {
    deleteModel({ id: deletedItem })
    handleCloseDeleteModal()
  }, [deletedItem])

  const handleChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);

  useEffect(() => {
    if (error) {
      errorToast(error);
    }
  }, [error]);

  interface Values {
    name: string;
  }

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('required')),
  });

  return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography gutterBottom variant="h5" component="div">{t('mark')}</Typography>
    <Box sx={{ display: 'flex' }} onClick={() => handleOpen()}>
      <AddIcon />
    </Box>
    <DeleteModal
      deleteFunction={deleteFunction}
      handleClose={handleCloseDeleteModal}
      open={!!deletedItem}
    />
    
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ minWidth: 200, mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 4, paddingBottom: 4 }}>
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            addModel(values);
            handleClose()
          }}
        >
          {({ errors, touched }: any) => (
            <Form>
              <Box sx={{ minWidth: 200, mb: 2 }}>
                <FormControl >
                  <CustomTextField name='name' label={t('mark')} variant="outlined" />
                </FormControl>
              </Box>
              <Button type='submit' variant="contained">{t('create')}</Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        height: '100%',
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
      {data?.result?.map((item) => (
        <ListItem key={item.id}>
          <ListItemText primary={item?.name} />
          <DeleteIcon onClick={() => item.id ? handleOpenDeleteModal(item.id) : console.log('fg')
          } className='cusor-pointer' />
        </ListItem>
      ))}
    </List>
    {data?.result[0] && data?.result[0]?.count > 10 && <Pagination count={Math.ceil(data?.result[0]?.count / 10)} color="primary" page={page} onChange={handleChange} />}
  </Box>
}
export default Mark;