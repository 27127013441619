import { Grid } from '@mui/material';
import { TextField, Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../Components/CustomTextFIeld/idex';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

function Login() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  interface Values {
    username: string;
    password: string;
  }
  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .required('Պարտադիր է'),
    password: Yup.string()
      .required('Պարտադիր է'),
  });
  useEffect(()=>{
    if (localStorage.getItem('username')) {
      navigate('/cars')
    }
  },[])
  return <Grid container
    direction="column"
    justifyContent="center"
    alignItems="center"

  >
    <Box sx={{ mt: 12, mb: 12 }}>
      <Typography variant="h1" component="h2">
        Autocomplex
      </Typography>
      <Box sx={{ display:'flex', justifyContent:'center' }}><img src='/autocomplex-logo.svg'/></Box>
    </Box>
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={(
        values: Values,
        { setSubmitting }: FormikHelpers<Values>
      ) => {
        if (values.username === 'Autocomplex2023' && values.password === 'Autocomplex123456') {
          localStorage.setItem('username', values.username)
          navigate('/cars')
        } else {
          toast.error('Դուք մուտքագրել եք սխալ տվյալներ')
        }
      }}
    >
      {({ errors, touched }: any) => (
        <Form>
          <Box sx={{ mt: 1 }}><CustomTextField name='username' label={t('login')} /></Box>
          <Box sx={{ mt: 1 }}><CustomTextField name='password' type='password' label={t('password')} /></Box>
          <Box sx={{ mt: 2 }}><Button variant="contained" type="submit">{t('signIn')}</Button></Box>
        </Form>
      )
      }
    </Formik >
  </Grid >
}
export default Login;