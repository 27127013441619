import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";

function Utils() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const { t } = useTranslation()
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseGallery = () => {
    setOpenGallery(false);
  };
  const handleOpenGallery = () => {
    setOpenGallery(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const generateDate = () => {
    let i = 2000;
    let numberArray = [];
    while (i < 2051) {
      numberArray.push(i)
      i++
    }
    return numberArray;
  }

  const SignupSchema = Yup.object().shape({
    modelId: Yup.string()
      .required(t('required')),
    marksId: Yup.string()
      .required(t('required')),
    year: Yup.number()
      .required(t('required')),
    price: Yup.number()
      .required(t('required')),
    mileage: Yup.number()
      .required(t('required')),
    mileageType: Yup.string()
      .required(t('required')),
    location: Yup.string(),
    vin: Yup.string()
      .required(t('required')),
    descriptionArm: Yup.string()
      .required(t('required')),
    descriptionRu: Yup.string()
      .required(t('required')),
    descriptionEn: Yup.string()
      .required(t('required')),
    colorArm: Yup.string()
      .required(t('required')),
    colorRu: Yup.string()
      .required(t('required')),
    colorEn: Yup.string()
      .required(t('required')),
    // driveType: Yup.string()
    // .required(t('required')),
    transmission: Yup.string()
      .required(t('required')),
    body: Yup.string()
      .required(t('required')),
    wheel: Yup.string()
      .required(t('required')),

  });
  const itemData = [
    {
      img: '/car.webp',
      title: 'car1',
    },
    {
      img: '/car.webp',
      title: 'car1',
    },
    {
      img: '/car.webp',
      title: 'car1',
    },
    {
      img: '/car.webp',
      title: 'car1',
    },
    {
      img: '/car.webp',
      title: 'car1',
    },
    {
      img: '/car.webp',
      title: 'car1',
    },
    {
      img: '/car.webp',
      title: 'car1',
    },
    {
      img: '/car.webp',
      title: 'car1',
    },

  ];

  const dialogSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return {
    dialogSettings,
    itemData,
    SignupSchema,
    open,
    openGallery,
    handleClickOpen,
    generateDate,
    handleClose,
    handleOpenGallery,
    handleCloseGallery,
    navigate
  }
}
export default Utils;