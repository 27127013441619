import React, { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../Components/DeleteModal';
import { useDeleteSearchedCarsMutation, useGetSearchedCarsQuery } from '../../store/services/searchedCars';

export default function App() {
  const [deletedItem, setDeletedItem] = useState<number | null>(null);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const { data: carData } = useGetSearchedCarsQuery({
    offset: (page - 1) * 10,
    limit: 100000000
  });
  const [deleteCar] = useDeleteSearchedCarsMutation();

  const handleChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);

  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    setDeletedItem(null)
  }, [])

  const deleteFunction = useCallback(() => {
    deleteCar({ id: deletedItem })
    handleClose()
  }, [deletedItem])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1976d2',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
      <DeleteModal
        deleteFunction={deleteFunction}
        handleClose={handleClose}
        open={!!deletedItem}
      />
      <Box sx={{ display: 'flex', gap: 3 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('mark')}/{t('model')}</StyledTableCell>
                <StyledTableCell align="right">{t('options')}</StyledTableCell>
                <StyledTableCell align="right">{t('carColor')}</StyledTableCell>
                <StyledTableCell align="right">{t('mileage')}</StyledTableCell>
                <StyledTableCell align="right">{t('engineVolume')}</StyledTableCell>
                <StyledTableCell align="right">{t('hp')}</StyledTableCell>
                <StyledTableCell align="right">{t('transmission')}</StyledTableCell>
                <StyledTableCell align="right">{t('driveType')}</StyledTableCell>
                <StyledTableCell align="right">{t('interier')}</StyledTableCell>
                <StyledTableCell align="right">{t('interierColor')}</StyledTableCell>
                <StyledTableCell align="right">{t('primaryDamage')}</StyledTableCell>
                <StyledTableCell align="right">{t('date')}</StyledTableCell>
                <StyledTableCell align="right">{t('phoneNumber')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carData?.result && carData?.result?.map((row: any, index: number) => {
                return <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    <Box onClick={() => navigate(`/searched-cars/${row.id}`)} sx={{ cursor: 'pointer' }}>{row?.modelName} / {row?.markName}</Box>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row?.options}</StyledTableCell>
                  <StyledTableCell align="right">{row?.carColor}</StyledTableCell>
                  <StyledTableCell align="right">{row?.mileage}</StyledTableCell>
                  <StyledTableCell align="right">{row?.engineVolume}</StyledTableCell>
                  <StyledTableCell align="right">{row?.hp}</StyledTableCell>
                  <StyledTableCell align="right">{row?.transmission}</StyledTableCell>
                  <StyledTableCell align="right">{row?.driveType}</StyledTableCell>
                  <StyledTableCell align="right">{row?.interier}</StyledTableCell>
                  <StyledTableCell align="right">{row?.interierColor}</StyledTableCell>
                  <StyledTableCell align="right">{row?.primaryDamage}</StyledTableCell>
                  <StyledTableCell align="right">{row?.year}</StyledTableCell>
                  <StyledTableCell align="right">{row?.phoneNumber}</StyledTableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
          {carData?.result[0]?.count > 10 && <Pagination count={Math.ceil(carData?.result[0]?.count / 10)} color="primary" page={page} onChange={handleChange} />}
        </TableContainer>
      </Box>
    </Box>

  );
}
