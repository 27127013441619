import { Box, ImageListItem, List, ListItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import Pagination from '@mui/material/Pagination';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../Components/DeleteModal';
import { useAddEmployeeMutation, useDeleteEmployeeMutation, useGetEmployeeQuery } from '../../store/services/Employee';
import { useAppDispatch } from '../../store';


function Employee() {
    const { t } = useTranslation();
    const [deletedItem, setDeletedItem] = useState<number | null>(null);
    const [page, setPage] = useState<number>(1);
    const { data } = useGetEmployeeQuery({ limit: 10, offset: (page - 1) * 10 });
    const [deleteEmployee] = useDeleteEmployeeMutation({});
    const [addEmployee] = useAddEmployeeMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleOpenDeleteModal = useCallback((id: number) => {
        setDeletedItem(id)
    }, [])
    const handleCloseDeleteModal = useCallback(() => {
        setDeletedItem(null)
    }, [])
    const deleteFunction = useCallback(() => {
        deleteEmployee({ id: deletedItem })
        handleCloseDeleteModal()
    }, [deletedItem])
    const handleChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }, []);
    const onChangeHandler = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const formdata = new FormData();
            formdata.append('image', file)
            addEmployee(formdata);
        }
    };
    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <Typography gutterBottom variant="h5" component="div">{t('employee')}</Typography>
            <Box onClick={() => navigate('/add-employee')}><AddIcon /> </Box>
        </Box>
        <DeleteModal
            deleteFunction={deleteFunction}
            handleClose={handleCloseDeleteModal}
            open={!!deletedItem}
        />

        <List
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                height: '100%',
                '& ul': { padding: 0 },
            }}
            subheader={<li />}
        >
            {data?.result?.map((item: any) => {
                console.log(item, 'item.image');
                return <Box sx={{
                    position: 'relative',
                    width: 360,
                }}><ListItem key={item.id}>
                        <ImageListItem key={item} sx={{ width: '100%' }}>
                            <img
                                style={{ height: '300px', width: '100%' }}
                                src={item.image !== 'null' ? `${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.image}` : '/picture.jpg'}
                                srcSet={item.image !== 'null' ? `${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.image}` : '/picture.jpg'}
                                alt='img'
                                loading="lazy"
                            />
                            <Box>
                                <Typography gutterBottom component="div">
                                    {item?.name}
                                </Typography>
                                <Typography gutterBottom component="div">
                                    {item?.proffesion}
                                </Typography>
                                <Typography gutterBottom component="div">
                                    {item?.phoneNumber}
                                </Typography>
                            </Box>
                        </ImageListItem>

                        <Box sx={{
                            position: 'absolute',
                            top: '20px', right: '30px',
                            zIndex: 1,
                            background: 'white',
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><DeleteIcon onClick={() => item.id ? handleOpenDeleteModal(item.id) : console.log('fg')
                        } className='cusor-pointer' />
                        </Box>
                        <Box sx={{
                            position: 'absolute',
                            top: '20px', right: '65px',
                            zIndex: 1,
                            background: 'white',
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <EditIcon onClick={() => navigate(`/employee/${item.id}`)
                            } className='cusor-pointer' />
                        </Box>
                    </ListItem></Box>
            })}
        </List>
        {data?.result[0] && data?.result[0]?.count > 10 && <Pagination
            count={Math.ceil(data?.result[0]?.count / 10)}
            color="primary"
            page={page}
            onChange={handleChange}
        />}
    </Box>
}
export default Employee;