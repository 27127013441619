import { useState, useEffect } from 'react';
import { Dialog, IconButton, ImageList, ImageListItem, Stack, Toolbar, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'react-slick';
import Lightbox from '../../../Components/LightBox';
import { deleteImage, setImages } from '../../../store/slices/images';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IImages } from '../../../types';
import { useGetCarsByIdQuery } from '../../../store/services/cars';
import { useAddImagesMutation } from '../../../store/services/photo';
import { useTranslation } from 'react-i18next';

export default function Callery({ id }: { id: string | undefined }) {
  const [open, setOpen] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [index, setIndex] = useState(1);
  const { data: dataCar } = useGetCarsByIdQuery({ id });
  const images = useAppSelector((state) => state.images.images);
  const imagescONT = useAppSelector((state) => state.images);
  const [addImages] = useAddImagesMutation({});
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const handleClickOpen = (current: number) => {
    setOpen(true);
    setIndex(current);
  };

  const handleCloseGallery = () => {
    setOpenGallery(false);
  };
  const handleOpenGallery = () => {
    setOpenGallery(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUploadFiles = (ind: any) => {

    dispatch(deleteImage(ind))
  }

  const onChangeHandler = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const formdata = new FormData();
      formdata.append('image', file)
      addImages(formdata);
    }
  };

  useEffect(() => {
    if (dataCar?.result) {
      dispatch(setImages(dataCar?.result[0].images))
    }
  }, [dataCar])

  useEffect(() => {
    try {
      if (!open) {
        setIndex(1)
      }
    } catch (error) {
      console.log(error);
    }

  }, [open])

  const dialogSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings = {
    customPaging: function (i: number) {
      return (
        <a>
          <img src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${images[i]?.path}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div>
      <Box sx={{ marginTop: 8 }} onClick={handleOpenGallery}><AddIcon /></Box>

      <Box sx={{ maxWidth: '100%', width: 400, marginTop: 2, marginBottom: 8 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Slider {...settings}>
            {images.map((el: IImages, ind: number) => (<div key={ind} onClick={() => handleClickOpen(ind)}>
              <img
                src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${el.path}`}
                alt='img'
                loading="lazy"
                className='w-100 add-car-img'
              />
            </div>))}
          </Slider>
          <Box>
            <Dialog
              fullWidth={true}
              maxWidth="md"
              open={open}
              onClose={handleClose}
            >
              <Lightbox index={index} itemData={images} />
            </Dialog>
          </Box>
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={openGallery}
            onClose={handleCloseGallery}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
              </IconButton>

            </Toolbar>
            <Box sx={{ maxWidth: '100%', width: '80%', margin: 'auto', gap: 3 }}>
              <Stack sx={{ display: 'flex', justifyContent: 'center', mb: 3 }} direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label">
                  {t('upload')}
                  <input hidden accept="image/*" type="file" onChange={(e) => {
                    onChangeHandler(e)
                  }} />
                </Button>
              </Stack>
              <ImageList sx={{ width: 500, height: 450, margin: 'auto' }} cols={3} rowHeight={164}>
                {images.map((item: any, ind: number) => {
                  return <Box sx={{ position: 'relative' }} className='imageListItem' key={ind}>
                    <Box sx={{
                      position: 'absolute',
                      top: 3,
                      right: 3,
                      background: 'white',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      zIndex: 1000,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                      onClick={() => handleDeleteUploadFiles(ind)}
                    ><CloseIcon /></Box>
                    <ImageListItem key={item}>
                      <img
                        src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.path}`}
                        srcSet={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${item.path}`}
                        alt='img'
                        loading="lazy"
                        className='add-images'
                      />
                    </ImageListItem>
                  </Box>
                })}
              </ImageList>
            </Box>
            <Button onClick={handleCloseGallery}>Հաստատել</Button>
          </Dialog>
        </Box>
      </Box>
    </div>
  );
}