import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './locales/ru/ns1.json';
import am from './locales/am/ns1.json';
export const defaultNS = "ns1";
export const resources = {
  ru,
  am
} as const;

i18n.use(initReactI18next).init({
  lng: 'am',

  interpolation: {
    escapeValue: false
  },
  resources,
});
export default i18n