import { Box, Button, FormControl, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useCallback, useState, useEffect } from 'react';
import { useDeleteDashImagesMutation } from '../../store/services/dashImages';
import DeleteModal from '../../Components/DeleteModal';
import { deleteImage, setImages } from '../../store/slices/images';
import { useAddImagesMutation } from '../../store/services/photo';
import { useAppDispatch, useAppSelector } from '../../store';
import { CustomTextField } from '../../Components';
import { Formik, FormikHelpers, Form } from 'formik';
import { useAddEmployeeMutation } from '../../store/services/Employee';

function EmployeeAdd() {
    const { t } = useTranslation();
    const [deletedItem, setDeletedItem] = useState<number | null>(null);
    const images = useAppSelector((state: any) => state.images.images);
    const [addEmployee,{error, isSuccess}] = useAddEmployeeMutation();
    const [deleteImages] = useDeleteDashImagesMutation({});
    const [addImages, { isLoading }] = useAddImagesMutation({});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const errorToast = (err: any) => toast.error(err ? err.error : 'someting went wrong');

    const handleOpenDeleteModal = useCallback((id: number) => {
        setDeletedItem(id)
    }, [])
    const handleCloseDeleteModal = useCallback(() => {
        setDeletedItem(null)
    }, [])
    const deleteFunction = useCallback(() => {
        deleteImages({ id: deletedItem })
        handleCloseDeleteModal()
    }, [deletedItem])

    const handleDeleteUploadFiles = (ind: any) => {

        dispatch(deleteImage(ind))
    }
    const onChangeHandler = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const formdata = new FormData();
            formdata.append('image', file)
            addImages(formdata);
        }
    };
    interface Values {
        name: string,
        proffesion: string,
        phoneNumber: string,
        nameRu: string,
        nameEn: string,
        proffesionRu: string,
        proffesionEn: string
    }
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required(t('required')),
        proffesion: Yup.string()
            .required(t('required')),
            nameEn: Yup.string()
            .required(t('required')),
        proffesionEn: Yup.string()
            .required(t('required')),
            nameRu: Yup.string()
            .required(t('required')),
        proffesionRu: Yup.string()
            .required(t('required')),
        phoneNumber: Yup.string()
        .required(t('required')),
    });
    useEffect(() => {
        if (error) {
            errorToast(error);
        }
    }, [error])
    useEffect(() => {
        if (isSuccess) {
            dispatch(setImages([]))
        }
    }, [isSuccess])
    useEffect(() => {
        return function cleanup() {
            dispatch(setImages([]))
          };
    }, [])
    return <Formik
        initialValues={{
            name: '',
            nameEn: '',
            nameRu: '',
            proffesion: '',
            proffesionEn: '',
            proffesionRu: '',
            phoneNumber: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
        ) => {
            try {
                addEmployee({ ...values, image: images[images.length - 1]?.path? images[images.length - 1]?.path:null })
                navigate('/employee')
            } catch (error: any) {
                errorToast(error.message)
            }

        }}
    >
        {({ errors, touched, handleChange }: any) => (
            <Form >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography gutterBottom variant="h5" component="div">{t('addEmployee')}</Typography>
                    <DeleteModal
                        deleteFunction={deleteFunction}
                        handleClose={handleCloseDeleteModal}
                        open={!!deletedItem}
                    />

                    <Stack sx={{ display: 'flex', mb: 3 }} direction="row" alignItems="center" spacing={2}>
                        <Button variant="contained" component="label">
                            {t('upload')}
                            <input hidden accept="image/*" type="file" onChange={(e) => {
                                onChangeHandler(e)
                            }} />
                        </Button>
                    </Stack>
                    { images[images.length - 1] &&  <Box sx={{ position: 'relative', width:'500px' }}>
                        <img className='add-employee-img'
                            src={`${process.env.REACT_APP_API_KEY}upload/getImageByName/${images[images.length - 1]?.path}`}
                        />
                        <Box sx={{
                            position: 'absolute',
                            top: '20px', right: '30px',
                            zIndex: 1,
                            background: 'white',
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><DeleteIcon onClick={() => handleDeleteUploadFiles(images.length - 1)
                        } className='cusor-pointer' />
                        </Box>
                    </Box>}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ width: 200, mb: 2 }}>
                            <FormControl fullWidth>
                                <CustomTextField name='name' label={t('name')} variant="outlined" />
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 200, mb: 2 }}>
                            <FormControl fullWidth>
                                <CustomTextField name='nameEn' label={`${t('name')} (en)`} variant="outlined" />
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 200, mb: 2 }}>
                            <FormControl fullWidth>
                                <CustomTextField name='nameRu' label={`${t('name')} (ru)`} variant="outlined" />
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 200, mb: 2 }}>
                            <FormControl fullWidth>
                                <CustomTextField name='proffesion' label={t('proffesion')} variant="outlined" />
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 200, mb: 2 }}>
                            <FormControl fullWidth>
                                <CustomTextField name='proffesionEn' label={`${t('proffesion')} (en)`} variant="outlined" />
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 200, mb: 2 }}>
                            <FormControl fullWidth>
                                <CustomTextField name='proffesionRu' label={`${t('proffesion')} (ru)`} variant="outlined" />
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 200, mb: 2 }}>
                            <FormControl fullWidth>
                                <CustomTextField name='phoneNumber' label={t('phoneNumber')} variant="outlined" />
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Button type='submit' variant="contained">{t('create')}</Button>
            </Form>
        )}
    </Formik>
}
export default EmployeeAdd;