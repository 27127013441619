import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import { carsApi } from './services/cars';
import { markApi, modelApi } from './services/markmodel';
import {photoApi} from './services/photo';
import {employeeApi} from './services/Employee';
import imagesSlice from './slices/images';
import {dashImagesApi} from './services/dashImages';
import { searchedCarsApi } from './services/searchedCars';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector
} from 'react-redux'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [carsApi.reducerPath]: carsApi.reducer,
    [markApi.reducerPath]: markApi.reducer,
    [modelApi.reducerPath]: modelApi.reducer,
    [photoApi.reducerPath]:photoApi.reducer,
    [employeeApi.reducerPath]:employeeApi.reducer,
    [dashImagesApi.reducerPath]:dashImagesApi.reducer,
    [searchedCarsApi.reducerPath]: searchedCarsApi.reducer,
    images:imagesSlice
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      carsApi.middleware,
       markApi.middleware, 
       modelApi.middleware, 
       photoApi.middleware, 
       dashImagesApi.middleware,
       searchedCarsApi.middleware,
       employeeApi.middleware
      ]),
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)