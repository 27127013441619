import { Login } from './Pages';
import {
  createBrowserRouter
} from "react-router-dom";
import App from './App';
import ResponsiveDrawer from './Layout/Drawer';
import Carpage from './Pages/CarPage';
import AddCar from './Pages/AddCar';
import Mark from './Pages/Mark';
import Model from './Pages/Model';
import DashImages from './Pages/DashImages';
import SearchedCars from './Pages/SearchedCars';
import SearchedCarsId from './Pages/SearchedCars/SearchedCarsId';
import Employee from './Pages/Employee';
import EmployeeAdd from './Pages/EmployeeAdd';
import EmployeePage from './Pages/EmployeePage';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Login />
    ),
  },
  {
    path: "/mark",
    element: (
      <ResponsiveDrawer><Mark /></ResponsiveDrawer>
    ),
  },
  {
    path: "/model",
    element: (
      <ResponsiveDrawer><Model /></ResponsiveDrawer>
    ),
  },
  {
    path: "/cars",
    element: (
      <ResponsiveDrawer><App /></ResponsiveDrawer>
    ),
  },
  {
    path: "/add-car",
    element: (
      <ResponsiveDrawer><AddCar /></ResponsiveDrawer>
    ),
  },
  {
    path: "/cars/:id",
    element: (
      <ResponsiveDrawer><Carpage /></ResponsiveDrawer>
    ),
  },
  {
    path: "/searched-cars/:id",
    element: (
      <ResponsiveDrawer><SearchedCarsId /></ResponsiveDrawer>
    ),
  },
  {
    path: "/searched-cars",
    element: (
      <ResponsiveDrawer><SearchedCars /></ResponsiveDrawer>
    ),
  },
  {
    path: "/dashImages",
    element: (
      <ResponsiveDrawer><DashImages /></ResponsiveDrawer>
    ),
  },
  {
    path: "/employee",
    element:(
      <ResponsiveDrawer><Employee /></ResponsiveDrawer>
    )
  },
  {
    path: "/add-employee",
    element:(
      <ResponsiveDrawer><EmployeeAdd /></ResponsiveDrawer>
    )
  },
  {
    path: `/employee/:id`,
    element:(
      <ResponsiveDrawer><EmployeePage /></ResponsiveDrawer>
    )
  }


]);
export default router